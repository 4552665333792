import GoogleMapsApiLoader from "google-maps-api-loader";

const apiKey = "AIzaSyAmcPtktTXIW8vL2vdX1hzZVUg8HPN67B4";
let google = {};
let distanceMatrix = {};
let placesService = {};

export const loadGoogleMapsApi = async () => {
  if (window.google == undefined) {
    google = await GoogleMapsApiLoader({
      libraries: ["places"],
      apiKey,
    });
    distanceMatrix = new google.maps.DistanceMatrixService();
    placesService = new google.maps.places.PlacesService(document.createElement("div"));
    return google;
  }
  google = window.google;
  distanceMatrix = new google.maps.DistanceMatrixService();
  placesService = new google.maps.places.PlacesService(document.createElement("div"));
  return google;
};

export const getGeolocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      }, (error) => {
        reject(error);
      },
      { enableHighAccuracy: true }
    );
  } else {
    reject("Geolocation is not supported by this browser.");
  }
});

export const getDistance = (origin, destination) => new Promise((resolve, reject) => {
  distanceMatrix.getDistanceMatrix({
    origins: [origin],
    destinations: [destination],
    travelMode: "DRIVING",
  }, (response, status) => {
    if (status == "OK") {
      const kms = response.rows[0].elements[0].distance.text;
      resolve(`${kms} away`);
    } else {
      reject("Error getting distance");
    }
  }
  );
});

export const getHaversineDistance = (origin, destination) => new Promise((resolve, reject) => {
  const earthRadius = 6371; // Earth's radius in kilometers
  let lat1 = origin.lat;
  let lon1 = origin.lng;
  let lat2 = destination.lat;
  let lon2 = destination.lng;

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let distance = earthRadius * c; // Distance in kilometers
  distance = Math.round(distance * 100) / 100; // Round to 2 decimal places
  
  resolve(`${distance} km`);

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
});

export const getPlaceDetails = (request) => new Promise((resolve, reject) => {
  placesService.getDetails(request, (place, status) => {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      resolve(place);
    } else {
      reject("Error getting place details");
    }
  });
});

export function debounce(fn, ms) {
  let timeout = null;
  return (...args) => {
    const call = () => fn(...args);
    clearTimeout(timeout);
    timeout = setTimeout(call, ms);
  };
}
