<template>
  <!-- App -->
  <f7-app
    :params="f7params"
    v-bind:class="{ 'theme-dark': theme }"
    :color-theme="color_theme"
  >
    <!-- Left Panel -->
    <f7-panel left cover>
      <f7-view url="/panel-left/">
        <sac-c-pp-custompanelleft />
      </f7-view>
    </f7-panel>

    <!-- Right Panel -->
    <f7-panel right cover>
      <f7-view url="/panel-right/">
        <div class="sa-sidebar-img">
          <img :src="sidebarImg(config)" />
        </div>
        <sac-c-pp-custompanelright />
      </f7-view>
    </f7-panel>

    <!-- Main View -->
    <f7-view id="main-view" :push-state="pushState" url="/" main />
  </f7-app>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import { store } from "./store/store.js";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
import SAF7AppCore from "@sa-platform/sa-p-core/framework7-app-core.vue";
import * as util from "@/util";

import SAGeneralCore from "./components/sac-c-sageneral-components/components/sac-c-sageneral-core/sac-c-sageneral-core.vue";

const SAPlatformStore = window.SAPlatform.stores.SAPlatformStore;

export default {
  mixins: [SAF7AppCore, SAGeneralCore],
  computed: {
    ...mapState({
      user: (state) => state.app.user,
      editedPost: (state) => state.posts.post_edited,
    }),
    colorTheme: {
      get() {
        return "";
        return this.$store.getters["SATemplate/getMainColorTheme"];
      },
    },
    config: {
      get() {
        return this.$store.getters["SATemplate/getConfig"];
      },
    },
    localeTexts() {
      return this.$data.texts[this.$data.lang];
    },
  },
  data() {
    return {
      template1: "<p></p>",
      // Framework7 parameters here
      f7params: {
        // App bundle ID
        id: "com.superapp.sa-mobile-app",
        // App name
        name: "sa-mobile-app",
        navbar: {
          mdCenterTitle: true,
        },
      },
      lang: window.SAPlatform.language.getCurrent() || 'en',
      texts: {
        en: {
          pwd_forgot_prompt_title: "Reset password",
          pwd_forgot_prompt_msg: "Type in email address.",
          pwd_forgot_success_title: "Password restored",
          pwd_forgot_success_msg: "Check your email. Password should arrive in couple of minutes. Remember to check junk mail too.",
          pwd_forgot_failure_title: "Reset not possible!",
          pwd_forgot_failure_msg: "Check you've typed in correct email address: ",
          activation_alert_title: "Account not activated!",
          activation_alert_msg: "Check your email for activation link.",
          discard_post_confirm_title: "Warning",
          discard_post_confirm_msg: "You have unsaved changes. Are you sure you want to leave this page? Changes will be lost.",
        },
        fi: {
          pwd_forgot_prompt_title: "Salasanan palautus",
          pwd_forgot_prompt_msg: "Anna sähköpostiosoite, johon salasana lähetetään.",
          pwd_forgot_success_title: "Salasana palautettu",
          pwd_forgot_success_msg: "Salasana saapuu sähköpostiisi muutaman minuutin kuluessa.",
          pwd_forgot_failure_title: "Palautus ei onnistunut!",
          pwd_forgot_failure_msg: "Tarkista, että antamasi sähköpostiosoite on oikein: ",
          activation_alert_title: "Tiliä ei ole aktivoitu!",
          activation_alert_msg: "Tarkista aktivointilinkki sähköpostistasi.",
          discard_post_confirm_title: "Varoitus",
          discard_post_confirm_msg: "Sinulla on tallentamattomia muutoksia. Haluatko varmasti poistua sivulta? Muutokset menetetään.",
        },
      },
    };
  },
  methods: {
    sidebarImg(config) {
      return (config && config.data && config.data["main-sidebar-img"]) || "";
    },
    backButtonInit() {
      const self = this;
      document.addEventListener(
        "deviceready",
        () => {
          document.addEventListener("backbutton", onBackKeyDown, false);

          function onBackKeyDown(event) {
            event.preventDefault();
            event.stopPropagation();

            const app = self.$f7;
            const leftp = app.panel.left && app.panel.left.opened;
            const rightp = app.panel.right && app.panel.right.opened;

            if (leftp || rightp) {
              app.panel.close();
              return false;
            } else if (self.$$(".modal-in").length > 0) {
              app.dialog.close();
              app.popup.close();
              app.popover.close();

              return false;
            } else if (app.views.main.router.history.length === 1) {
              navigator.app.exitApp();
            } else {
              app.views.main.router.back();
              return false;
            }
          }
        },
        false
      );
    },
    async getFilterItems() {
      await this.$store.commit('initialiseStore');
      this.$store.dispatch("app/getFilterItems")
    },
    checkPhoneVerified() {
      let user = window.SAPlatform.user.current();
      let phoneChanged = user.get('phone_changed');
      let phoneVerified = user.get('phone_verified');

      if (phoneChanged == 'true' || phoneVerified != 'true') {
        let userId = user.data.ID;
        let phone = user.get("phone");

        axios.post(window.SAPlatform.server.url + "wp-json/superapp/v1/verification-code/send", {
          user_id: userId,
          phone: phone,
        });
        this.$f7.view.current.router.navigate("/verification/", {});
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.getFilterItems();
    util.loadGoogleMapsApi();

    /** Session event listeners (needed)  */

    //Listening log in
    this.listen("user-session-created", (data) => {
      console.log("user-session-created");

      //removing history so android backbutton works correctly
      this.$f7.views.main.router.history = [];

      setTimeout(() => {
        let user = window.SAPlatform.user.current();
        let userLang = user.get('locale');
        if (userLang) {
          this.$data.lang = userLang;
          window.SAPlatform.language.setCurrent(userLang);
        }
      }, 500);

      setTimeout(async () => {
        await this.$store.dispatch("app/getUser");

        if (this.user.hasOwnProperty('vendor_id') && this.user.vendor_id) {
          await this.$store.dispatch("app/getVendorProfile", this.user.vendor_id);
        } else {
          let position = null;
          await util.getGeolocation().then((pos) => {
            position = { lat: pos.coords.latitude, lng: pos.coords.longitude };
          }).catch((err) => {
            position = null;
          });
          await this.$store.dispatch("activity/getUserActivity", position);
        }
      }, 1000);
    });

    //Password forgot window
    this.listen("forget-pw", (data) => {
      this.$f7.dialog.prompt(
        this.localeTexts.pwd_forgot_prompt_msg,
        this.localeTexts.pwd_forgot_prompt_title,
        (email) => {
          window.SAPlatform.user
            .requestPasswordReset(email)
            .then((response) => {
              this.$f7.dialog.alert(
                this.localeTexts.pwd_forgot_success_msg,
                this.localeTexts.pwd_forgot_success_title
              );
            })
            .catch((error) => {
              this.$f7.dialog.alert(
                this.localeTexts.pwd_forgot_failure_msg + email,
                this.localeTexts.pwd_forgot_failure_title
              );
            });
        }
      );
    });

    //Listening log out
    this.listen("logout-user", (data) => {
      console.log("Logging out");
      window.SAPlatform.user.LogOut();

      //removing history so android backbutton works correctly
      this.$f7.views.main.router.history = [];
      this.$f7.view.current.router.navigate("/", {});

      this.$store.dispatch("resetAll");
    });

    this.listen("login-user", (data) => {
      console.log("Logging in");
      let user = window.SAPlatform.user.current();
      let link;
      let userRole = user.data.roles[0];
      if (userRole == "appuser") {
        let newUser = user.get('new_user');
        let accountActivated = user.get('account_activated');
        if (newUser == 'no' && accountActivated == 1) {
          link = "/main-feed/";
        } else if (newUser == 'yes' && accountActivated == 1) {
          link = "/main-feed/";
          user.set('new_user', 'no');
          user.save();
        } else {
          link = "/register/thank-you-for-signing/";
        }

        this.$f7.view.current.router.navigate(link, { animate: true, transition: 'f7-fade' });
        this.$f7.views.main.router.history = [];
      } else if (userRole == "vendoruser") {
        let verified = this.checkPhoneVerified();
        if (verified) {
          link = "/vendor-home/";

          this.$f7.view.current.router.navigate(link, { animate: true, transition: 'f7-fade' });
          this.$f7.views.main.router.history = [];
        }
      }
    });

    //Listening some login
    this.listen("social-login", (data) => {
      window.SAPlatform.user
        .socialLogIn(data.detail.value, { target: "safariviewcontroller" })
        .then((response) => {
          var user = window.SAPlatform.user.current();
          var email = user.get("user_email");
          console.log(user);
          console.log(email);
          this.$f7.view.current.router.navigate("/448/", {});
          this.$f7.views.main.router.history = [];
        });
    });

    //Listening language change
    this.listen("change-language", (data) => {
      if (data.detail && data.detail.value) {
        console.log("Changing language:");
        window.SAPlatform.language.setCurrent(data.detail.value);
      }
    });

    this.listen("check-activation", data => {
      let user = window.SAPlatform.user.current();
      user.save();
      let newUser = user.get('new_user');
      let accountActivated = user.get('account_activated');

      if (newUser == 'yes' && accountActivated == 0) {
        this.$f7.dialog.alert(
          this.localeTexts.activation_alert_msg,
          this.localeTexts.activation_alert_title
        );
      } else {
        user.set('new_user', 'no');
        user.save();
        this.$f7.view.current.router.navigate("/main-feed/");
      }
    });

    //Listening form response
    this.listen("sa-form-got-response", async event => {
      const formData = event.detail.data;
      if (formData.form == 'registeruser' && formData.data.code == 'USER_CREATED') {
        const username = formData.items.username.value;
        const pw = formData.items.pw1.value;

        await window.SAPlatform.user.logIn(username, pw);
        this.$f7.view.current.router.navigate("/register/thank-you-for-signing/");
      } else if (formData.form == 'userinfo' && formData.data.code == 'USER_UPDATED') {
        SAPlatformStore.dispatch('form/clear',"userinfo");
        let user = window.SAPlatform.user.current();
        user.save();
        await this.$store.dispatch("app/getUser");
      }
    });

    //Listening update vendor profile
    this.listen("update-vendor", (data) => {
      this.$store.dispatch("app/getUser");
      this.$store.dispatch("app/getVendorProfile", this.user.vendor_id);
      if (data.detail == 'true') {
        this.$f7.views.main.router.back();
      }
    });

    //Listening update vendor user
    this.listen("update-vendor-user", (data) => {
      let user = window.SAPlatform.user.current();
      let phoneChanged = user.get('phone_changed');
      if (phoneChanged == 'true') {
        user.set('phone_verified', 'false');
        user.save();
        let userId = user.data.ID;
        let phone = user.get("phone");
        axios.post(window.SAPlatform.server.url + "wp-json/superapp/v1/verification-code/send", {
          user_id: userId,
          phone: phone,
        });
        this.$f7.view.current.router.navigate('/verification/', {});
      } else {
        this.$f7.view.current.router.navigate('/vendor-home/', {});
      }
    });

    //Listening sainput update
    this.listen("sainput-update", (data) => {
      if (data.detail.item.form == 'vendorinfo' && data.detail.item.name == "phone") {
        let newPhone = data.detail.item.value;
        let user = window.SAPlatform.user.current();
        let oldPhone = user.get('phone');
        if (newPhone != oldPhone) {
          user.set('phone_changed', 'true');
          user.save();
        } else {
          user.set('phone_changed', 'false');
          user.save();
        }
      }
    });

    // Listening back from profile-form
    this.listen("clear-phonechange", (data) => {
      let user = window.SAPlatform.user.current();
      user.set('phone_changed', 'false');
      user.save();
    });

    this.listen("route-page", (data) => {
      if (data.detail && data.detail.value) {
        this.$f7.views.main.router.navigate(data.detail.value);
      } else if (data.detail) {
        this.$f7.views.main.router.navigate(data.detail);
      }
    });

    this.listen("check-modified-post", (data) => {
      if (this.editedPost === true) {
        this.$f7.dialog.confirm(
          this.localeTexts.discard_post_confirm_msg,
          this.localeTexts.discard_post_confirm_title,
          () => {
            this.$store.dispatch("posts/resetCurrentPost");
            this.$f7.views.main.router.back();
          }
        );
      } else {
        this.$store.dispatch("posts/resetCurrentPost");
        this.$f7.views.main.router.back();
      }
    });

    //Internet connection alerts */
    document.addEventListener("offline", (e) => {
      dialog = this.$f7.dialog.create({
        title: "No internet connection",
        text: "This app needs an internet connection to work.",
        buttons: [
          {
            text: "OK",
          },
        ],
      });

      dialog.open();
    });

    /*  Handling WKWebView issues with IOS here */
    if (window.WkWebView) {
      //fixes keyboard issues if user does back swipe
      this.$f7.view.current.router.app.on("pageAfterIn", (page) => {
        window.scrollBy({ top: 0, left: 0, behaviour: "smooth" });
      });

      //handles keyboard issues with basic focusout events by scrolling up
      document.addEventListener("focusout", () => {
        window.scrollBy({ top: 0, left: 0, behaviour: "smooth" });
      });
    }

    //android backbutton init
    this.backButtonInit();

    /** Hardcoded login redirects (temporary solution)  */
    if (window.cordova) {
      let user = window.SAPlatform.user.current();
      if (!user) {
        console.log("user not logged in. redirecting to login page");
        this.$f7.views.main.router.history = [];
      } else {
        console.log("logged in",user);
        let userLang = user.get("locale");
        this.$data.lang = userLang;
        window.SAPlatform.language.setCurrent(userLang);

        let link;
        let userRole = user.data.roles[0];
        if (userRole == "appuser") {
          let newUser = user.get('new_user');
          let accountActivated = user.get('account_activated');
          if (newUser == 'no' && accountActivated == 1) {
            link = "/main-feed/";
          } else if (newUser == 'yes' && accountActivated == 1) {
            link = "/main-feed/";
            user.set('new_user', 'no');
            user.save();
          } else {
            link = "/register/thank-you-for-signing/";
          }

          this.$f7.view.current.router.navigate(link, { animate: true, transition: 'f7-fade' });
          this.$f7.views.main.router.history = [];
        } else if (userRole == "vendoruser") {
          let verified = this.checkPhoneVerified();
          if (verified) {
            link = "/vendor-home/";

            this.$f7.view.current.router.navigate(link, { animate: true, transition: 'f7-fade' });
            this.$f7.views.main.router.history = [];
          }
        }
      }
    }
  },
};
</script>
