
import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';
import * as util from "@/util";
const _ = require('lodash');

const apiUrl = url => window.SAPlatform.server.url + "wp-json/superapp/v1" + url;

Vue.use(Vuex);

const app = {
  namespaced: true,
  state: {
    user: [],
    vendor_profile: [],
    filter_items: {
      terms: [],
      countries: [],
    },
    filter_choices: {
      distance: 25,
      country: "",
      city: "",
      slugs: [],
      only_open: false,
    },
    feed: [],
    feed_page: 1,
    search_results: [],
  },
  mutations: {
    addUser(state, data) {
      state.user = data;
    },
    addVendorProfile(state, data) {
      state.vendor_profile = data;
    },
    addFilterItems(state, data) {
      state.filter_items = data;
      if (state.filter_choices.country === "") {
        state.filter_choices.country = data.countries[0].country_en;
      }
    },
    addFilterDistance(state, data) {
      state.filter_choices.distance = data;
    },
    addFilterCountry(state, data) {
      state.filter_choices.country = data;
    },
    addFilterCity(state, data) {
      state.filter_choices.city = data;
    },
    addFilterSlugs(state, data) {
      state.filter_choices.slugs = data;
    },
    addFilterOnlyOpen(state, data) {
      state.filter_choices.only_open = data;
    },
    addFilterPage(state, data) {
      state.filter_choices.page = data;
    },
    addFeed(state, data) {
      state.feed = data;
    },
    updateFeed(state, data) {
      state.feed = _.uniqBy(state.feed.concat(data), 'content_id');
    },
    updateFeedPage(state, data) {
      state.feed_page = data;
    },
    addSearchResults(state, data) {
      state.search_results = data;
    },
    resetUser(state) {
      state.user = [];
    },
    resetVendorProfile(state) {
      state.vendor_profile = [];
    },
    resetFilterChoices(state) {
      state.filter_choices = {
        distance: 25,
        country: "",
        city: "",
        slugs: [],
        only_open: false,
      };

      state.filter_choices.country = state.filter_items.countries[0].country_en;
    }
  },
  actions: {
    async getUser(context) {
      const url = apiUrl('/user');
      return await axios.get(url).then(response => {
        context.commit("addUser", response.data);
      });
    },
    async getVendorProfile(context, vendorId) {
      const url = apiUrl('/vendor/profile');
      const params = {
        vendor_id: vendorId,
        lang: window.SAPlatform.language.current,
      };
      return await axios.get(url, { params: params }).then(response => {
        context.commit("addVendorProfile", response.data);
      });
    },
    async getFilterItems(context) {
      const url = apiUrl('/filter/items');
      let lang = window.SAPlatform.language.current;
      return await axios.get(url, { params: { lang: lang }}).then(response => {
        context.commit("addFilterItems", response.data);
      });
    },
    async getFeed(context, data) {
      let page = data.page;
      let position = data.position;

      const url = apiUrl('/feed');
      const params = {
        distance: context.state.filter_choices.distance,
        position: position,
        country: context.state.filter_choices.country,
        city: context.state.filter_choices.city,
        slugs: context.state.filter_choices.slugs.join(","),
        only_open: context.state.filter_choices.only_open,
        page: page,
      };
      return await axios.get(url, { params: params }).then(response => {
        if (page > 1) {
          context.commit("updateFeed", response.data.posts);
          context.commit("updateFeedPage", response.data.page);
        } else {
          context.commit("addFeed", response.data.posts);
          context.commit("updateFeedPage", response.data.page);
        }

        return response.data;
      });
    },
    async searchVendors(context, { value, position, signal }) {
      const source = axios.CancelToken.source(); // Create a cancel token source
      signal.addEventListener('abort', () => {
        source.cancel('Search aborted'); // Cancel the request when the signal is aborted
      });

      const url = apiUrl('/search');
      const params = {
        search: value,
        position: position,
      };

      try {
        const response = await axios.get(url, {
          params: params,
          cancelToken: source.token, // Set the cancel token
        });

        context.commit("addSearchResults", response.data);

        return response.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, handle accordingly if needed
        } else {
          // Handle other errors
          throw error;
        }
      }
    },
    setFilterDistance(context, data) {
      context.commit("addFilterDistance", data);
    },
    setFilterCountry(context, data) {
      context.commit("addFilterCountry", data);
    },
    setFilterCity(context, data) {
      context.commit("addFilterCity", data);
    },
    setFilterSlugs(context, data) {
      context.commit("addFilterSlugs", data);
    },
    setFilterOnlyOpen(context, data) {
      context.commit("addFilterOnlyOpen", data);
    },
    setFilterPage(context, data) {
      context.commit("addFilterPage", data);
    },
    resetUser(context) {
      context.commit("resetUser");
    },
    resetVendorProfile(context) {
      context.commit("resetVendorProfile");
    },
    resetFilterChoices(context) {
      context.commit("resetFilterChoices");
    }
  },
  getters: {},
};

const activity = {
  namespaced: true,
  state: { follows: [], pins: [] },
  mutations: {
    addActivity(state, data) {
      state.follows = data.follows;
      state.pins = data.pins;
    },
    resetActivity(state) {
      state.follows = [];
      state.pins = [];
    }
  },
  actions: {
    async getUserActivity(context, position) {
      const url = apiUrl('/activity/user');
      const params = {
        position: position,
      };
      return await axios.get(url, { params: params }).then(response => {
        context.commit("addActivity", response.data);
      });
    },
    resetActivity(context) {
      context.commit("resetActivity");
    }
  },
  getters: {},
};

const notifications = {
  namespaced: true,
  state: { vendor: [], user: [], },
  mutations: {
    addVendorNotifications(state, data) {
      state.vendor = data;
    },
    addUserNotifications(state, data) {
      state.user = data;
    },
    resetVendor(state) {
      state.vendor = [];
    },
    resetUser(state) {
      state.user = [];
    }
  },
  actions: {
    async getVendorNotifications(context, vendorId) {
      const url = apiUrl('/vendor/notifications');
      const params = {
        vendor_id: vendorId,
      };
      return await axios.get(url, { params: params }).then(response => {
        context.commit("addVendorNotifications", response.data);
      });
    },
    async getUserNotifications(context) {
      const url = apiUrl('/notifications');
      return await axios.get(url).then(response => {
        context.commit("addUserNotifications", response.data);
      });
    },
    resetVendor(context) {
      context.commit("resetVendor");
    },
    resetUser(context) {
      context.commit("resetUser");
    }
  },
  getters: {},
};

const reviews = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async sendReview(context, data) {
      const url = apiUrl('/reviews/send');
      const payload = {
        vendor_id: data.vendorId,
        user_id: data.userId,
        parent_id: data.parentId,
        message: data.message,
      };
      return await axios.post(url, payload).then(response => {
        return response.data;
      });
    },
  },
  getters: {},
};

const rewards = {
  namespaced: true,
  state: { current_user: [], customer_user: [],  },
  mutations: {
    addCurrentUserRewards(state, data) {
      state.current_user = data;
    },
    addCustomerUserRewards(state, data) {
      state.customer_user = data;
    },
    resetCurrentUser(state) {
      state.current_user = [];
    },
    resetCustomerUser(state) {
      state.customer_user = [];
    }
  },
  actions: {
    async getCurrentUserRewards(context) {
      const url = apiUrl('/rewards/user');
      return await axios.get(url).then(response => {
        context.commit("addCurrentUserRewards", response.data);
      });
    },
    getCustomerUserRewards(context, [vendorId, customerNo, userId = null]) {
      const url = apiUrl('/rewards/user');
      return new Promise((resolve, reject) => {
        const params = {
          vendor_id: vendorId,
          customer_no: customerNo,
          user_id: userId
        };
        axios.get(url, { params: params })
        .then(response => {
          context.commit("addCustomerUserRewards", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    async updateUserScore(context, [vendorId, userId, recordId, program, score]) {
      const url = apiUrl('/rewards/update-score');
      const payload = {
        vendor_id: vendorId,
        user_id: userId,
        record_id: recordId,
        program: program,
        score: score,
      };
      return await axios.post(url, payload).then(response => {
        context.dispatch("getCustomerUserRewards", [vendorId, null, userId]);
      });
    },
    async claimReward(context, [vendorId, userId, program, recordId]) {
      const url = apiUrl('/rewards/claim');
      const payload = {
        vendor_id: vendorId,
        user_id: userId,
        program: program,
        record_id: recordId,
      };
      return await axios.post(url, payload).then(response => {
        context.dispatch("getCustomerUserRewards", [vendorId, null, userId]);
      });
    },
    async savePrograms(context, payload) {
      const url = apiUrl('/rewards/save-programs');
      return await axios.post(url, payload);
    },
    resetCurrentUser(context) {
      context.commit("resetCurrentUser");
    },
    resetCustomerUser(context) {
      context.commit("resetCustomerUser");
    }
  },
  getters: {},
};

const posts = {
  namespaced: true,
  state: {
    post_edited: false,
    form_data: {},
    current_post: {
      content_id: '',
			type_id: null,
			type_slug: '',
			title_1: '',
			title_2: '',
			text: '',
			flag_color: '',
			flag_text_row_1:'',
			flag_text_row_2: '',
      image: '',
      image_url: '',
      new_image: '',
			is_template: false,
      schedule: false,
      schedule_template: '',
      schedule_start: '',
      schedule_end: '',
      recurrence: false,
      recurrence_start_date: '',
      recurrence_start_time: '',
      recurrence_end_time: '',
      recurrence_days: [],
      weekly_recurrence: false,
      stop_recurrence: ''
    },
    templates: [
      {
        id: '',
        url: '',
      }
    ]
  },
  mutations: {
    addTemplates(state, data) {
      state.templates = data;
    },
    addCurrentPost(state, data) {
      state.current_post = data;
    },
    updateCurrentPost(state, data) {
      state.post_edited = true;
      state.current_post[data.field] = data.value;
    },
    addFormData(state, data) {
      state.form_data = data;
    },
    resetCurrentPost(state) {
      state.post_edited = false;
      state.current_post = {
        content_id: '',
        type_id: null,
        type_slug: '',
        title_1: '',
        title_2: '',
        text: '',
        flag_color: '',
        flag_text_row_1:'',
        flag_text_row_2: '',
        image: '',
        image_url: '',
        new_image: '',
        is_template: false,
        schedule: false,
        schedule_template: '',
        schedule_start: '',
        schedule_end: '',
        recurrence: false,
        recurrence_start_date: '',
        recurrence_start_time: '',
        recurrence_end_time: '',
        recurrence_days: [],
        weekly_recurrence: false,
        stop_recurrence: ''
      };
    }
  },
  actions: {
    async getTemplates(context) {
      const url = apiUrl('/templates');
      return await axios.get(url).then(response => {
        context.commit("addTemplates", response.data);
      });
    },
    setCurrentPost(context, id) {
      const post = _.cloneDeep(context.rootState.app.vendor_profile.content.find(content => content.content_id === id));
      context.commit("addCurrentPost", post);
    },
    updateCurrentPost(context, data) {
      context.commit("updateCurrentPost", data);
    },
    setFormData(context, data) {
      context.commit("addFormData", data);
    },
    async addNewImage(context) {
      const formData = context.state.form_data;
      const url = apiUrl('/vendor/content/add-image');
      return await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }}).then((response) => {
        if (response.data['file_id']) context.commit("updateCurrentPost", { field: 'image', value: response.data['file_id'] });
        if (response.data['file_url']) context.commit("updateCurrentPost", { field: 'image_url', value: response.data['file_url'] });
        context.commit("updateCurrentPost", { field: 'new_image', value: '' });
        context.commit("addFormData", {});
        return response.data;
      });
    },
    async savePost(context) {
      const url = apiUrl('/vendor/content/save');
      const payload = Object.assign({}, context.state.current_post);
      payload.vendor_id = context.rootState.app.vendor_profile.vendor_id;
      return await axios.post(url, payload).then(response => {
        context.commit("resetCurrentPost");
      });
    },
    resetCurrentPost(context) {
      context.commit("resetCurrentPost");
    }
  },
  getters: {},
};

const analytics = {
  namespaced: true,
  state: {
    results: {}
  },
  mutations: {
    addAnalytics(state, data) {
      state.results = data;
    },
  },
  actions: {
    async getAnalytics(context, vendorId) {
      const url = apiUrl('/analytics/get');
      const params = {
        vendor_id: vendorId,
      };

      return await axios.get(url, { params: params }).then(response => {
        context.commit("addAnalytics", response.data);
      });
    },
  },
  getters: {},
};

export const store = new Vuex.Store({
  state: {},
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    }
  },
  actions: {
    resetAll(context) {
      context.dispatch("app/resetUser", null, { root: true });
      context.dispatch("app/resetVendorProfile", null, { root: true });
      context.dispatch("app/resetFilterChoices", null, { root: true });
      context.dispatch("activity/resetActivity", null, { root: true });
      context.dispatch("notifications/resetVendor", null, { root: true });
      context.dispatch("notifications/resetUser", null, { root: true });
      context.dispatch("rewards/resetCurrentUser", null, { root: true });
      context.dispatch("rewards/resetCustomerUser", null, { root: true });
    }
  },
  getters: {},
  modules: {
    app,
    activity,
    notifications,
    reviews,
    rewards,
    posts,
    analytics
  },
});

store.subscribe((mutation, state) => {
  try {
    localStorage.setItem('store', JSON.stringify(state));
  }
  catch (e) {
    console.log(e);
  }
});
