import "framework7-icons";
import "material-design-icons/iconfont/material-icons.css";
import "framework7/css/framework7.bundle.css";
import SAPlatform from "@sa-platform/sa-p-core/core.js";
import Framework7 from "framework7/framework7.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";
import App from "./app";
import Vue from "vue";
import Vuex from "vuex";
import AppStyles from "./css/app.scss";
import Area51 from "./templates/area51.vue";
import Area52 from "./templates/area52.vue";
import Page from "@sa-platform/sa-t-page/sa-t-page.vue";
import SessionEvents from "@sa-platform/sa-p-core/session-events.js";

// Import Vuex
import {store} from "./store/store.js";

let app;

SAPlatform.startApp({version: "1.0.0", Vue}, (data) => {

  // Let's get routes
  const routes = data.routes;

  /*
   * here you can define custom routes (Can be modified).
   */
  routes.push({path: "/preview/", component: Page});

  routes.push({path: "/area51/", component: Area51});
  routes.push({path: "/area51/:routePl/", component: Area51});

  routes.push({path: "/area52/", component: Area52});
  routes.push({path: "/area52/:routePl/", component: Area52});

  if (window.SAPlatform.server.disable) {
    /* Will be executed if backend connection is disabled (Can be modified).  */
    routes.push({path: "/", component: Area51});
  }

  window.routes = routes;

  // Initing VueX
  Vue.use(Vuex);

  // Init F7 Vue Plugin
  Framework7.use(Framework7Vue);

  // Init App
  app = new Vue({
    el: "#app",
    template: "<app/>",
    pushState: true,
    store,

    // Register App Component
    components: {
      app: App,
    },
  });

  SessionEvents.init(app);
});
